import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
import "firebase/performance";
import "firebase/analytics";
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyCDY1Kc6s5MRQlXU23UEdDZDdhuV8v-vIE",
	authDomain: "socialife-ch.firebaseapp.com",
	databaseURL:
		"https://socialife-ch-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "socialife-ch",
	storageBucket: "socialife-ch.appspot.com",
	messagingSenderId: "837093571698",
	appId: "1:837093571698:web:d2a27c46de4ffd6e7e16eb",
	measurementId: "G-W52HZW2CHP",
};

firebase.initializeApp(firebaseConfig);

export default firebase.firestore();
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const connectedRef = firebase.database().ref(".info/connected");
export const firebaseRef = firebase;
export const fbFunctions = firebase.app().functions("europe-west6");
export const fbPerf = firebase.performance();
export const storageRef = firebase.storage().ref();
