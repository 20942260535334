import React, { useState } from "react";
import {
	Row,
	Col,
	Button,
	Form,
	Input,
	Card,
	Statistic,
	Avatar,
	Divider,
	Typography,
	PageHeader,
	Drawer,
} from "antd";
import { Link } from "react-router-dom";
import Icon, {
	InstagramOutlined,
	YoutubeFilled,
	LockFilled,
} from "@ant-design/icons";
import numeral from "numeral";

import { fbFunctions } from "./data/Firebase";
import instagramExtract from "./data/instagramExtract";
import tiktokExtract from "./data/tiktokExtract";
import youtubeExtract from "./data/youtubeExtract";

import { ReactComponent as TiktokSvg } from "./icons/tiktok.svg";

import "./App.less";

const { Meta } = Card;
const { Title } = Typography;

const SocialDetails = ({ username, avatar, statistics, rating, type }) => (
	<Form.Item>
		<Card className="card-statistics" bordered={true}>
			<Meta
				title={
					<>
						{username}{" "}
						{type === "instagram" ? (
							<InstagramOutlined />
						) : type === "tiktok" ? (
							<TiktokSvg className="stat-icon" />
						) : (
							type === "youtube" && <YoutubeFilled />
						)}
					</>
				}
				avatar={<Avatar src={avatar} />}
			/>
			<Row justify="space-between">
				{Object.keys(statistics).map((stat, key) => (
					<Col key={key}>
						<Statistic
							formatter={(value) => (
								<>
									<b>{numeral(value).format("0.[00]a")}</b>
									{typeof rating !== "undefined" && "/" + rating}
								</>
							)}
							title={stat.charAt(0).toUpperCase() + stat.slice(1)}
							value={statistics[stat]}
						/>
					</Col>
				))}
			</Row>
		</Card>
	</Form.Item>
);

const App = () => {
	const { Search } = Input;
	const influencerType = {
		instagram: null,
		tiktok: null,
		youtube: null,
	};

	const [influencers, setInfluencers] = useState([
		{ ...influencerType },
		{ ...influencerType },
	]);
	const [ratings, setRatings] = useState(null);
	const [instaLoading, setInstaLoading] = useState(false);
	const [tiktokLoading, setTiktokLoading] = useState(false);
	const [youtubeLoading, setYoutubeLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [privateProfile, setPrivateProfile] = useState(false);
	const forms = [Form.useForm()[0], Form.useForm()[0]];

	const networkCall = async (value, index, network) => {
		if (value !== "") {
			try {
				let extract;
				switch (network) {
					case "instagram":
						setInstaLoading(true);
						setPrivateProfile(false);
						extract = await instagramExtract(value);
						if (extract.private) {
							setPrivateProfile(true);
						}
						break;

					case "tiktok":
						setTiktokLoading(true);
						extract = await tiktokExtract(value);
						break;

					case "youtube":
						setYoutubeLoading(true);
						extract = await youtubeExtract(value);
						break;

					default:
						break;
				}
				console.log(extract);
				const newArray = [...influencers];
				newArray[index][network] = extract;
				setInfluencers(newArray);
			} catch (error) {
				console.log(error);
			}
			setInstaLoading(false);
			setTiktokLoading(false);
			setYoutubeLoading(false);
		}
	};

	const caluclate = async (e) => {
		setLoading(true);
		try {
			const testRatingFirebase = fbFunctions.httpsCallable("testRating");
			const promises = influencers.map(async (influencer) =>
				testRatingFirebase(influencer)
			);
			const newRatings = (await Promise.all(promises)).map(
				(result) => result.data
			);
			console.log(newRatings);
			setRatings(newRatings);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const reset = () => {
		forms.forEach((form) => form.resetFields());
		setInfluencers([{ ...influencerType }, { ...influencerType }]);
	};

	const influencerRating = (index) => {
		if (ratings !== null) {
			const instagram = ratings[index].instagram;
			const tiktok = ratings[index].tiktok;
			const youtube = ratings[index].youtube;
			const alphabet = ["A", "B", "C", "D"];
			return (
				<>
					<Title level={5}>Influencer {alphabet[index]}</Title>
					{instagram !== null && (
						<SocialDetails
							type="instagram"
							rating={10}
							username={instagram.username}
							avatar={instagram.avatar}
							statistics={{
								engagement: instagram.engagementRate,
								follow: instagram.followRate,
								frequency: instagram.frequencyRate,
								growth: instagram.growthRate,
							}}
						/>
					)}
					{youtube !== null && (
						<SocialDetails
							type="youtube"
							rating={10}
							username={youtube.username}
							avatar={youtube.avatar}
							statistics={{
								engagement: youtube.engagementRate,
								frequency: youtube.frequencyRate,
								growth: youtube.growthRate,
								reach: youtube.reachRate,
							}}
						/>
					)}
					{tiktok !== null && (
						<SocialDetails
							type="tiktok"
							rating={10}
							username={tiktok.username}
							avatar={tiktok.avatar}
							statistics={{
								engagement: tiktok.engagementRate,
								follow: tiktok.followRate,
								frequency: tiktok.frequencyRate,
								growth: tiktok.growthRate,
								reach: tiktok.reachRate,
							}}
						/>
					)}
				</>
			);
		}
	};

	const influencer = (index) => {
		const instagram = influencers[index].instagram;
		const tiktok = influencers[index].tiktok;
		const youtube = influencers[index].youtube;
		const alphabet = ["A", "B", "C", "D"];

		return (
			<Form
				name="influencer_apply"
				className="login-form"
				initialValues={{ remember: true }}
				size="large"
				form={forms[index]}
			>
				<Title level={5}>Influencer {alphabet[index]}</Title>
				<Form.Item name="instagram">
					<Search
						prefix="@"
						type="text"
						placeholder="Instagram"
						enterButton={<InstagramOutlined className="site-form-item-icon" />}
						onBlur={(e) => networkCall(e.target.value, index, "instagram")}
						onSearch={(val) => networkCall(val, index, "instagram")}
						loading={instaLoading}
					/>
				</Form.Item>
				{instagram !== null && (
					<SocialDetails
						username={
							<>
								{instagram.username} {instagram.private && <LockFilled />}
							</>
						}
						avatar={instagram.avatar}
						statistics={{
							posts: instagram.postCount,
							followers: instagram.followers,
							following: instagram.following,
						}}
					/>
				)}

				<Form.Item name="tiktok">
					<Search
						prefix="@"
						type="text"
						placeholder="TikTok"
						enterButton={<Icon component={TiktokSvg} />}
						onBlur={(e) => networkCall(e.target.value, index, "tiktok")}
						onSearch={(val) => networkCall(val, index, "tiktok")}
						loading={tiktokLoading}
					/>
				</Form.Item>

				{tiktok !== null && (
					<SocialDetails
						username={tiktok.username}
						avatar={tiktok.avatar}
						statistics={{
							posts: tiktok.postCount,
							followers: tiktok.followers,
							following: tiktok.following,
						}}
					/>
				)}

				<Form.Item name="youtube">
					<Search
						prefix="@"
						type="text"
						placeholder="Youtube"
						enterButton={<YoutubeFilled className="site-form-item-icon" />}
						onBlur={(e) => networkCall(e.target.value, index, "youtube")}
						onSearch={(val) => networkCall(val, index, "youtube")}
						loading={youtubeLoading}
					/>
				</Form.Item>
				{youtube !== null && (
					<SocialDetails
						username={youtube.username}
						avatar={youtube.avatar}
						statistics={{
							videos: youtube.postCount,
							followers: youtube.followers,
							views: youtube.viewCount,
						}}
					/>
				)}
			</Form>
		);
	};

	return (
		<>
			<PageHeader
				title="Socialife Rating"
				subTitle="Test the rating algorithm"
				extra={[
					<Link to="/">
						<Button>Rating</Button>
					</Link>,
					<Link to="/linkedin">
						<Button>Linkedin</Button>
					</Link>,
				]}
			/>
			<Row className="login-row" justify="center" gutter={[16, 16]}>
				<Col sm={8} md={7} lg={5} xl={4} xs={20}>
					{influencer(0)}
				</Col>
				<Col sm={0} xs={20} style={{ textAlign: "center" }}>
					<Divider type="horizontal" />
				</Col>
				<Col sm={1} xs={0} style={{ textAlign: "center" }}>
					<Divider style={{ height: "100%" }} type="vertical" />
				</Col>
				<Col sm={8} md={7} lg={5} xl={4} xs={20}>
					{influencer(1)}
				</Col>
				<Col xs={24} style={{ textAlign: "center" }}>
					<Button
						type="primary"
						size="large"
						loading={instaLoading || tiktokLoading || youtubeLoading || loading}
						disabled={privateProfile}
						onClick={caluclate}
					>
						Calculate
					</Button>
				</Col>
				<Col xs={24} style={{ textAlign: "center" }}>
					<Button type="ghost" size="large" onClick={reset}>
						Reset
					</Button>
				</Col>
			</Row>
			<Drawer
				width="100%"
				placement="right"
				closable={false}
				visible={ratings !== null}
			>
				<PageHeader
					onBack={() => setRatings(null)}
					title="Rating Results"
					subTitle="Calculated by Socialife"
				/>
				<Row className="login-row" justify="center" gutter={[0, 16]}>
					<Col sm={12} md={7} lg={5} xl={4} xs={20}>
						{influencerRating(0)}
					</Col>
					<Col md={0} xs={20} style={{ textAlign: "center" }}>
						<Divider type="horizontal" />
					</Col>
					<Col md={1} xs={0} style={{ textAlign: "center" }}>
						<Divider style={{ height: "100%" }} type="vertical" />
					</Col>
					<Col sm={12} md={7} lg={5} xl={4} xs={20}>
						{influencerRating(1)}
					</Col>
				</Row>
			</Drawer>
		</>
	);
};

export default App;
