import axios from "axios";

const proxyRequest = (url) =>
	axios.get(url, {
		proxy: {
			host: "zproxy.lum-superproxy.io",
			port: 22225,
			auth: {
				username: "lum-customer-hl_b3ac857e-zone-unblocker1-unblocker",
				password: "0remf5senvxc",
			},
		},
	});

export default proxyRequest;
