import React from "react";

import Rating from "./Rating";
import Linkedin from "./Linkedin";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const App = () => {
	return (
		<Router>
			<Switch>
				<Route path="/linkedin">
					<Linkedin />
				</Route>
				<Route path="/">
					<Rating />
				</Route>
			</Switch>
		</Router>
	);
};

export default App;
