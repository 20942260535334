import { fbFunctions } from "./Firebase";

const tiktokExtract = async (username) => {
	try {
		const tiktokCall = fbFunctions.httpsCallable("tiktokExtract");
		const user = (await tiktokCall({ username: username })).data;
		return user;
	} catch (error) {
		return error;
	}
};

export default tiktokExtract;
