import proxyRequest from "./proxyRequest";

const instagramExtract = async (username) => {
	try {
		const user = (
			await proxyRequest(`https://www.instagram.com/${username}/?__a=1`)
		).data.graphql.user;
		console.log(user);
		const posts = user.edge_owner_to_timeline_media.edges.map((post) => ({
			likes: post.node.edge_liked_by.count,
			paidLikes:
				post.node.edge_media_preview_like.count - post.node.edge_liked_by.count,
			comments: post.node.edge_media_to_comment.count,
			timestamp: post.node.taken_at_timestamp,
			thumbnail: post.node.thumbnail_src,
		}));
		return {
			username: user.username,
			followers: user.edge_followed_by.count,
			following: user.edge_follow.count,
			avatar: user.profile_pic_url_hd,
			posts: posts,
			postCount: user.edge_owner_to_timeline_media.count,
			private: user.is_private,
		};
	} catch (error) {
		return error;
	}
};

export default instagramExtract;
