import axios from "axios";
import moment from "moment";

const key = "AIzaSyCDY1Kc6s5MRQlXU23UEdDZDdhuV8v-vIE";

const youtubeExtract = async (username) => {
	try {
		const userId = (
			await axios.get(
				`https://youtube.googleapis.com/youtube/v3/search?maxResults=1&q=${username}&type=channel&key=${key}`
			)
		).data.items[0].id.channelId;
		const user = (
			await axios.get(
				`https://youtube.googleapis.com/youtube/v3/channels?part=snippet%2CcontentDetails%2Cstatistics&id=${userId}&key=${key}`
			)
		).data.items[0];
		const videosId = (
			await axios.get(
				`https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=${user.id}&maxResults=10&order=date&type=video&key=${key}`
			)
		).data.items.map((video) => video.id.videoId);
		const posts = (
			await axios.get(
				`https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=${videosId.join(
					"%2C"
				)}&key=${key}`
			)
		).data.items.map((post) => ({
			id: post.id,
			likes: post.statistics.likeCount,
			dislikes: post.statistics.dislikeCount,
			play: post.statistics.viewCount,
			comments: post.statistics.commentCount,
			timestamp: moment(post.snippet.publishedAt).unix(),
			thumbnail: post.snippet.thumbnails.high.url,
			duration: moment.duration(post.contentDetails.duration).asSeconds(),
		}));
		return {
			username: username,
			followers: user.statistics.subscriberCount,
			viewCount: user.statistics.viewCount,
			avatar: user.snippet.thumbnails.high.url,
			postCount: user.statistics.videoCount,
			posts: posts,
		};
	} catch (error) {
		return error;
	}
};

export default youtubeExtract;
