import React, { useState } from "react";
import {
	Row,
	Col,
	Button,
	Input,
	Card,
	Statistic,
	Avatar,
	PageHeader,
} from "antd";
import { Link } from "react-router-dom";
import { LinkedinFilled } from "@ant-design/icons";

import { fbFunctions } from "./data/Firebase";

import "./App.less";

const { Meta } = Card;

const Linkedin = () => {
	const { Search } = Input;
	//const cliendId = "77ix1sc3afpgyz";
	//const clientSecret = "upmd3C3ZeXUZm9aM";
	//const permissions = "r_liteprofile%20r_emailaddress%20w_member_social";
	//const callback = encodeURI(window.location.href);
	//const authRequest = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${cliendId}&redirect_uri=${callback}&state=fooobar&scope=${permissions}`;

	const [linkedinLoading, setLinkedingLoading] = useState(false);
	const [profile, setProfile] = useState(null);

	const noStat = ["name", "avatar", "current", "past", "title"];

	/*const authenticate = () => {
		window.open(
			authRequest,
			"Linkedin",
			"menubar=no,location=no,resizable=no,scrollbars=no,status=no, width="
		);
	};*/

	const extractProfile = async (url) => {
		setLinkedingLoading(true);
		const linkedinExtract = fbFunctions.httpsCallable("linkedinExtract");
		const profile = JSON.parse((await linkedinExtract({ url: url })).data);
		setProfile(profile);
		console.log(profile);
		setLinkedingLoading(false);
	};

	return (
		<>
			<PageHeader
				title="Socialife Linkedin"
				subTitle="Test Linkedin connectivity"
				extra={[
					<Link to="/">
						<Button>Rating</Button>
					</Link>,
					<Link to="/linkedin">
						<Button>Linkedin</Button>
					</Link>,
				]}
			/>
			<Row className="login-row" justify="center" gutter={[16, 16]}>
				<Col>
					<Search
						type="text"
						placeholder="https://www.linkedin.com/in/johndoe/"
						enterButton={<LinkedinFilled className="site-form-item-icon" />}
						onSearch={(val) => extractProfile(val)}
						loading={linkedinLoading}
					/>
				</Col>
				{profile !== null && (
					<Col>
						<Card className="card-statistics" bordered={true}>
							<Meta
								title={profile.name}
								avatar={<Avatar src={profile.avatar} />}
							/>
							<Row justify="space-between">
								{Object.keys(profile).map(
									(stat, key) =>
										!noStat.includes(stat) && (
											<Col key={key}>
												<Statistic
													title={stat.charAt(0).toUpperCase() + stat.slice(1)}
													value={profile[stat]}
												/>
											</Col>
										)
								)}
							</Row>
						</Card>
					</Col>
				)}
			</Row>
		</>
	);
};

export default Linkedin;
